import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import extractMeta from 'utils/extract-meta';
import { withUIContext } from 'context/ui';
import HeroIntro from 'components/hero-intro';
import ContentGrid from 'components/content-grid';

import SEO from 'components/seo';
import { Container } from 'components/layout';

import { DocumentNavigation, DocumentNavigationItem } from 'components/document-group';
import Pagination from 'components/pagination';
import s from './Resources.scss';

class Resource extends PureComponent {

  static propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.object,
    pageContext: PropTypes.object,
  }

  mapCard(obj) {
    return {
      heroImage: obj.thumbnail,
      title: obj.title,
      subTitle: obj.category,
      ctaUrl: obj.landingPageUrl,
    };
  }

  render() {
    const {
      page,
      all,
      unfiltered,
      filtered,
      categories,
    } = this.props.data;
    const { pathname } = this.props.location;

    const {
      currentPage,
      numberOfPages,
      pathBase,
      baseSlug,
      category: currentCategory,
    } = this.props.pageContext;

    const shouldAnimate = currentPage === 1;

    let cards = filtered && filtered.edges && filtered.edges.length > 0 ? filtered : unfiltered;

    cards = cards.edges.map(app => (this.mapCard(app.node)));

    const featuredCard = this.mapCard(page.featuredResource);

    // console.log('........', page.featuredResource, featuredCard)

    return (
      <div>
        <SEO pathname={pathname} article {...extractMeta(page)} htmlAttributes={{ class: 'smooth-scroll' }} />
        <HeroIntro
          heading={page.heroHeading}
          shouldAnimate={shouldAnimate}
          cancelHr
        />

        <Container>
          <div className={s.flex}>
            <div className={s.left}>
              <DocumentNavigation>
                <DocumentNavigationItem
                  key={0}
                  index={0}
                  text="All"
                  href={baseSlug}
                  active={!currentCategory}
                />
                {
                  categories.edges
                    .filter((categoryItem) => {
                      const hasAtLeastOneItem =
                        all.edges.reduce((accumulator, currentValue) => {
                          // console.log('Current value', currentValue.node.categoryNew.name === categoryItem.node.name);
                          const isSameCategory = currentValue.node.categoryNew.name === categoryItem.node.name;

                          return (accumulator || isSameCategory);
                        }, false);

                      return hasAtLeastOneItem;
                    })
                    .map(categoryItem => (
                      <DocumentNavigationItem
                        href={`${baseSlug}/${categoryItem.node.slug}`}
                        key={categoryItem.node.slug}
                        index={categoryItem.node.slug}
                        text={categoryItem.node.name}
                        active={categoryItem.node.name === currentCategory}
                      />
                    ))
                }
              </DocumentNavigation>
              <Pagination
                currentPage={currentPage}
                numberOfPages={numberOfPages}
                pathBase={pathBase}
              />
            </div>
            <ContentGrid
              cards={cards}
              featuredCard={featuredCard}
              showFeaturedCard={!(filtered && filtered.edges && filtered.edges.length > 0)}
              shouldAnimate={shouldAnimate}
            />
          </div>
        </Container>
      </div>
    );
  }

} export default withUIContext(Resource);
