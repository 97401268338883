import { graphql } from 'gatsby';
import Resources from 'routes/resources/Resources';

export default Resources;

export const query = graphql`
  fragment singleResource on ContentfulResource {
    title
    category
    landingPageUrl
    categoryNew {
      name
      slug
    }
    thumbnail {
      ...image
    }
  }

  query ResourcePage ($category: String!, $skip: Int!, $limit: Int!) {
    page: contentfulPageResource {
      heroHeading
      featuredResource {
        ...singleResource
      }
      metaImage {
        ...image
      }
    }

    all: allContentfulResource(
      filter: { categoryNew: { name: { ne: null } } },
      sort: { fields: title },
    ) {
      edges {
        node {
          ...singleResource
        }
      }
    }

    unfiltered: allContentfulResource(
      filter: { categoryNew: { name: { ne: null } } },
      limit: $limit,
      skip: $skip,
      sort: { fields: title },
    ) {
      edges {
        node {
          ...singleResource
        }
      }
    }

    filtered: allContentfulResource(
      filter: { categoryNew: { name: { eq: $category } } },
      limit: $limit,
      skip: $skip,
      sort: { fields: title },
    ) {
      edges {
        node {
          ...singleResource
        }
      }
    }

    categories: allContentfulResourceCategory(
      sort: { fields: name },
    ) {
      edges {
        node {
          name
          slug
        }
      }
    }
  }
`;
